export type NotificationType = 'error' | 'info' | 'success' | 'warning';
export const useNotify = () => {
  const notify = (message: string, type: NotificationType) => {
    const notifyEvent = new CustomEvent('notify', {
      detail: { message: message, type: type },
    });
    window.dispatchEvent(notifyEvent);
  };

  return notify;
};
